"use client";
import React, { useEffect } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Button } from '~/design-system/atom/button';
import { getCurrentClientSideHost } from '~/utils/global';
import router from 'next/router';
import { GoogleIcon } from '~/ui/svg/homepage';
import { postGoogleLogin } from '~/dataProcessor/api/api';
import { ANALYTICS } from '~/utils/tracking';
import { getCookie, setCookie } from '~/utils/cookie';
import * as Sentry from "@sentry/nextjs";

declare global {
    interface Window {
        google: any;
    }
}

const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || "920687958684-v45uflh67rhr3rou62kcffrmd9lajasi.apps.googleusercontent.com";

const GoogleSignInButton = () => {
    const postLoginCallback = async (data: { jwt?: string; authCode?: string}, type: "auth-code" | "jwt") => {
        try {
            const response = await postGoogleLogin(data, type);
            const { email } = response;
            console.log('Login successful!');

            if (email) {
                await ANALYTICS.identify(email, {
                    name: email,
                    email: email,
                });
            }
            setCookie("isLoggedIn", "true", 30);
            setCookie("composio_email", email || "", 30);
            Sentry.setUser({
                email: email,
            });
            router.push("/dashboard");
            // You can add more logic here, such as redirecting the user or storing the tokens
        } catch (error) {
            alert("Some error occurred while trying to log you in. Please try after sometime");
            console.error('Failed to login with Google:', error);
        }
    };
    console.log("UU",`${getCurrentClientSideHost()}/provider_login/google`);
    const login = useGoogleLogin({
        onSuccess: (credentialResponse: any) => {
            postLoginCallback({ authCode: credentialResponse.code }, "auth-code");
        },
        onError: () => {
            console.log('Login Failed');
        },
        flow: "auth-code",
        ux_mode: "redirect",
        select_account: true,
        redirect_uri: `${getCurrentClientSideHost()}/provider_login/google`
    });

    useEffect(() => {
        setTimeout(() => {
            if (!getCookie("isLoggedIn")) {
                window.google.accounts.id.initialize({
                    client_id: GOOGLE_CLIENT_ID,
                    callback: (credentialResponse: any) => {
                        postLoginCallback({ jwt: credentialResponse.credential }, "jwt");
                    },
                    cancel_on_tap_outside: false
                });
                window.google.accounts.id.prompt((notification: any) => {
                    if (notification.isNotDisplayed()) {
                        console.error('Google Sign-In prompt error:', notification.getNotDisplayedReason());
                    } else {
                        console.log('Google Sign-In prompt displayed successfully.');
                    }
                });
            }
        }, 1000);
    }, [login]);

    return (
        <Button
        title="Login with Google"
        variant={"default"}
        className="w-full bg-[#fff] text-[14px] text-black tracking-[.1px] shadow-[0px_0px_0px_1.5px_rgba(90,28,170,0.12)] hover:brightness-95"
        onClick={() => login()}
    >
        <div className="flex items-center gap-2 text-[13.5px] font-[600] leading-[13px] text-black">
            <GoogleIcon className="h-4 w-4 opacity-100" />
            <span className="pt-[1px]">Continue with Google</span>
        </div>
    </Button>
    );
};

const GoogleLoginComponent = () => {
    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} onScriptLoadError={() => {
            console.error("Error loading the script");
        }}>
            <GoogleSignInButton />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginComponent;
