import { css } from "@emotion/css";
import clsx from "clsx";
import React from "react";

export const NonAuthScreenBackdrop = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            className={clsx(
                `relative  h-[100vh] w-[100vw]  overflow-hidden`,
                css`
                    background: radial-gradient(100.09% 100.01% at 48.71% 0%, hsl(0, 0%, 96%) 0%, hsl(0, 0%, 93%) 78.13%);
                    //background: url("/assets/images/homepage/background.jpg") no-repeat center center;
                    background-size: cover;
                `,
            )}
        >
            <img src="/assets/images/global/illustration/curly_braces.svg" className="absolute left-[-4px] top-[-4px] z-10 h-[57px]" />
            <img src="/assets/images/global/illustration/boxy_website.svg" className="absolute bottom-[-4px] left-[-12px] z-10 h-[112px]" />
            <img
                src="/assets/images/global/illustration/colors_website_box.svg"
                className="absolute right-[-2px] top-[-8px] z-10 h-[156px]"
            />
            <div className="absolute left-[50%] top-[0] translate-x-[-50%]">
                <div className="h-[80vh] w-[92vw] border-b border-l border-r border-[#DADADA]"></div>
            </div>
            {children}
        </div>
    );
};
