import { useMutation } from "@tanstack/react-query";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { z } from "zod";

import { sendMagicLink } from "~/dataProcessor/api/auth";
import { NonAuthScreenBackdrop } from "~/ui/screens/login_screen/components/NonAuthScreenBackdrop";
import { getCookie } from "~/utils/cookie";
import { getCurrentClientSideHost } from "~/utils/global";

import { LoginComponent } from "../ui/screens/login_screen";

const useIsUserAuthenticated = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const isLoggedIn = getCookie("isLoggedIn");

        if (isLoggedIn === "true") {
            setIsAuthenticated(true);
            const skipOnboarding = getCookie("skipOnboarding");
            if (skipOnboarding === "true") {
                router.push("/apps");
                return;
            }
            router.push("/dashboard");
        }
    }, [router]);

    return isAuthenticated;
};

const LoginPage = () => {
    useIsUserAuthenticated();
    const [email, setEmail] = useState("");
    const [validationError, setValidationError] = useState<string | null>(null);
    const [showVerificationScreen, setShowVerificationScreen] = useState(false);

    const { mutate: sendMagicLinkFn, isLoading } = useMutation({
        mutationFn: ({ email }: { email: string }) => sendMagicLink(email, `${getCurrentClientSideHost()}/verify`),
        onSuccess: () => setShowVerificationScreen(true),
        onError: () => setValidationError("Something went wrong. Please try again later."),
    });

    const handleGetMagicLink = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            z.string().email().parse(email);
        } catch {
            const errorMessage = "Invalid email address";
            setValidationError(errorMessage);
            return;
        }

        try {
            // eslint-disable-next-line @typescript-eslint/await-thenable
            await sendMagicLinkFn({ email });
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "Invalid email address";
            setValidationError(errorMessage);
        }
    };

    const handleEmailChange = (newValue: string) => {
        setEmail(newValue);
        setValidationError(null);
    };

    return (
        <NonAuthScreenBackdrop>
            <Head>
                <title>Login | Composio</title>
            </Head>
            <LoginComponent
                email={email}
                handleGetMagicLink={handleGetMagicLink}
                isSubmitting={isLoading}
                onEmailChange={handleEmailChange}
                validationError={validationError}
                showVerificationScreen={showVerificationScreen}
                setShowVerificationScreen={setShowVerificationScreen}
            />
        </NonAuthScreenBackdrop>
    );
};

export default LoginPage;
