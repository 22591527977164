import { adjectives, animals, colors, uniqueNamesGenerator } from "unique-names-generator";

export const isEmailValid = (email: string) => {
    // regex to check if the email is valid
    const emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return emailRegex.test(email);
};

export const formatActionName = (name: string) => {
    if(!name) return name;

    return name
        .replace(/_/g, " ")
        .replace(/-/g, " ")
        .replace(/^\w/, (c) => c.toUpperCase());
};

export const formatUnderScoreString = (name: string) => {
    return name
        .replace(/_/g, " ")
        .replace(/-/g, " ")
        .replace(/^\w/, (c) => c.toUpperCase());
};

export const showFirstAndLast3Letters = (str: string) => {
    if (!str || str.length < 6) {
        return str;
    }
    return str.slice(0, 3) + "....." + str.slice(-3);
};

export const upperCaseAll = (str: string) => {
    if(!str) return;
    return str.toUpperCase();
};

export const upperCaseFirstLetter = (str: string) => {
    if(!str) return;
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateRandomName = () => {
    return uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        separator: "_",
        length: 2,
    });
};


export const showNLetters = (str: string, n: number) => {
    if(!str) return;
    if(str.length <= n) return str;
    return str.slice(0, n) + "...";
}