import clsx from "clsx";
import { motion } from "framer-motion";
import { ChevronLeftIcon, ChevronRight, MailIcon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";

import { Button } from "~/design-system/atom/button";
import { Input } from "~/design-system/atom/input";
import { LoadingIconPlain } from "~/ui/components/base/loadingIcon";
import ComposioLogo from "~/ui/components/base/logo";
import { EnterKey, GithubCircleIcon } from "~/ui/svg/homepage";
import { getCurrentClientSideHost } from "~/utils/global";
import { If } from "~/utils/reactComponent";
import { isEmailValid } from "~/utils/string";

import { Footer } from "./components/Footer";
import { LeftSection } from "./components/LeftSection";
import GoogleLogin from "./components/GoogleLogin";

export function LoginComponent({
    email,
    isSubmitting,
    onEmailChange,
    validationError,
    handleGetMagicLink,
    showVerificationScreen,
    setShowVerificationScreen,
}: {
    email: string;
    isSubmitting: boolean;
    onEmailChange: (email: string) => void;
    validationError: string | null;

    //input element event
    handleGetMagicLink: (e: React.SyntheticEvent) => void;
    showVerificationScreen: boolean;
    setShowVerificationScreen: (showVerificationScreen: boolean) => void;
}) {
    const inputRef = useRef<HTMLInputElement>(null);

    const router = useRouter();

    // on mount, focus on the input element
    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, []);

    return (
        <motion.div
            transition={{
                duration: 0.4,
            }}
            animate={{
                opacity: 1,
            }}
            initial={{
                opacity: 0,
            }}
            className="absolute relative left-[50%] top-[50%] z-20 flex h-[525px] w-[900px] max-w-[96%] translate-x-[-50%] translate-y-[-55%]  flex-col overflow-hidden rounded-[24px] border-[1px] border-[#DBDBDB] bg-[#F7F7F7] "
        >
            <div className="flex h-full">
                {/* Left Section */}
                <LeftSection />
                {/* Right Section */}
                <div className=" flex min-w-[424px] flex-col justify-between border-l-[1px] border-l-[#DBDBDB] bg-[#fff] px-[24px] pt-[36px] pt-[66xx] text-[14px] font-[400] text-[#606060] md:min-w-[100%] md:max-w-[100%]">
                    <div className="flex w-full flex-col items-center">
                        <ComposioLogo textColor={"#171717"} viewBox="0 0 150 33" height={24} />
                        <div className="mt-3 text-[14px] font-medium tracking-[0.35px] text-black-100">Take your AI agent live to prod</div>
                        <If condition={showVerificationScreen}>
                            <div className="mt-10 flex w-full flex-col items-center">
                                <div className="font-avenirn text-[17px] font-[600] text-black-200">Verify your email</div>
                                <div className="mt-3 text-center text-[13px] leading-[170%] text-black-600">
                                    We have sent a magic link to your email address. Please open it to continue.
                                </div>
                                <div
                                    onClick={() => setShowVerificationScreen(false)}
                                    className="text-black-6000 mt-6 flex cursor-pointer items-center gap-1 pr-2 text-[13px] font-[500] underline"
                                >
                                    <ChevronLeftIcon height={14} width={14} strokeWidth={2.2} />
                                    Go back
                                </div>
                            </div>
                        </If>
                        <If condition={!showVerificationScreen}>
                            <div className="mt-10 w-full">
                                <Button
                                    title="Get Magic Link"
                                    variant={"default"}
                                    className=" w-full bg-gradient-to-b from-[#8528F8] to-[#6919D2] text-[14px] tracking-[.1px] shadow-[0px_0px_0px_1.5px_rgba(90,28,170,0.12)] "
                                    onClick={() => {
                                        const currentHost = getCurrentClientSideHost();
                                        const url = `${currentHost}/provider_login/github`;

                                        router.push(
                                            `${process.env.NEXT_PUBLIC_API_URL}/api/v1/login/github/auth?redirectUri=${encodeURIComponent(url)}`,
                                        );
                                    }}
                                >
                                    <div className="flex items-center gap-2 text-[13.5px] font-[600] leading-[13px] text-white ">
                                        <GithubCircleIcon className="h-4 w-4 opacity-100" />
                                        <span className="pt-[1px]">Continue with Github</span>
                                    </div>
                                </Button>
                                <div className="mt-2">
                                <GoogleLogin/>

                                </div>
                                <hr className="my-6 mb-6" />
                                <div className="mb-3 w-full">
                                    <div className="relative">
                                        <Input
                                            className="h-[44px] flex-auto gap-1.5 border-[1.5px] border-grey-400 pt-3 text-[13.5px] font-[500] text-black-1000 placeholder:text-grey-700"
                                            type="email"
                                            ref={inputRef}
                                            placeholder="sam.alt@microsoft.com"
                                            icon={<MailIcon className="h-4 w-4 opacity-90 " />}
                                            value={email}
                                            onChange={(e) => onEmailChange(e.target.value)}
                                            onEnterPress={(e) => handleGetMagicLink(e)}
                                            rightIcon={isEmailValid(email) ? <EnterKey height={20} /> : null}
                                        />
                                    </div>
                                    {!!validationError && (
                                        <div className=" mb-1 ml-1 mt-[12px] text-[12px] text-[#c11247] ">{validationError}</div>
                                    )}
                                </div>
                                <Button
                                    title="Get Magic Link"
                                    variant={!isEmailValid(email) ? "plain" : "black"}
                                    size={40}
                                    className={clsx(
                                        " w-full border-grey-300 text-[14px] tracking-[.1px] ",
                                        [isSubmitting ? "cursor-wait" : "cursor-pointer"],
                                        [isEmailValid(email) ? "text-white" : "text-grey-600"],
                                    )}
                                    onClick={handleGetMagicLink}
                                    disabled={!isEmailValid(email)}
                                >
                                    <div className="flex items-center gap-2 text-[13px] font-[500] leading-[13px]   ">
                                        {!isSubmitting ? (
                                            <div className="flex w-full items-center justify-between px-2 pt-[2px]">
                                                <span>Send login link</span>
                                                <ChevronRight className="ml-1 h-4 w-4 opacity-100" />
                                            </div>
                                        ) : (
                                            <div className="flex w-full items-center justify-between gap-2">
                                                <span>Loading</span>
                                                <LoadingIconPlain size={16} />
                                            </div>
                                        )}
                                    </div>
                                </Button>
                            </div>
                        </If>
                    </div>
                    <If condition={!showVerificationScreen}>
                        <div className="mt-10 flex w-full items-center justify-center pb-6">
                            <Link
                                target="_blank"
                                href={"https://cal.com/team/composio/beta-access"}
                                className="text-[13px] font-[500] text-[#1C6FD0] underline"
                            >
                                Book a demo call
                            </Link>
                        </div>
                    </If>
                </div>
            </div>
            {/* Footer */}
            <Footer />
        </motion.div>
    );
}
